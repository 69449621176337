import fetch from 'node-fetch';
import Router from 'next/router';
import { ApolloLink } from '@apollo/client';
import { Observable } from 'rxjs';
import { onError } from "@apollo/link-error";
import { getAuthToken, getRefreshToken, setAuthToken, getRefreshExpiryTime, logout } from 'helper/user';
import Package from 'package.json';
import Log from './sentry';
import { LOGIN_ROUTE } from 'constants/navigation';
import { toHHMMSS } from './data/string';
import { logAnalytics } from './gtm/helper';

// AUTHENTICATION LINK

export const authLink = new ApolloLink((operation, forward) => {
  const authToken = getAuthToken();
  var devoHeaders = {
    'Devo-api-key': process.env.DEVO_API_KEY,
    'Devo-App-Version': Package.version,
  };
  if (authToken && getRefreshExpiryTime() > 0) {
    devoHeaders['Devo-auth-token'] = authToken;
  }
  
  operation.setContext(({ headers }) => ({ headers: { ...devoHeaders, ...headers }}));
  return forward(operation);
});

// REFRESH TOKEN

// @ts-ignore
export const refreshTokenLink = onError(({ forward, graphQLErrors, networkError, operation }) => {
  // @ts-ignore;
  const statusCode = networkError?.statusCode;

  if (statusCode && statusCode >= 500 && statusCode < 600) {
    Log.error(`Received ${statusCode} on ${operation.operationName} request`, 'api', operation.variables);
  } else if (statusCode && statusCode !== 401) {
    Log.warning(`Received ${statusCode} on ${operation.operationName} request`, 'api', operation.variables);
  } else {
    Log.info(`Received ${statusCode} on ${operation.operationName} request`, 'api', operation.variables);
  }

  if (["validateToken"].includes(operation.operationName)) {
    Log.debug(`IGNORING ${operation.operationName} request recovery!`, 'api', operation.variables);
    return;
  }
  
  if (statusCode === 401) {
    Log.info(`Received 401 on ${operation.operationName}, retrying auth...`, 'session', null);
    return new Observable(observer => {
      fetch(`${process.env.API_ACCOUNT_SERVICE}/auth/refresh`, {
        method: 'POST',
        body: JSON.stringify({
          refreshToken: getRefreshToken(),
        }),
        headers: {
          'Content-Type': 'application/json',
          'Devo-Api-Key': process.env.DEVO_API_KEY,
        }
      })
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          return response.json();
        } else {
          if (response.status >= 400 && response.status < 500) {
            Log.debug('Refreshing auth token unauthorized', 'session', null);
          } else {
            Log.error(`Refreshing auth token returned ${response.status}`, 'auth', { status: response.status, response, json: response.json() });
          }
          throw { name : "UnauthorizedRefreshToken", message : "The request to refresh the auth token was unauthorized." }; 
        }
      })
      .then(response => {
        const authToken = response?.authToken;
        if (authToken) {
          setAuthToken(authToken);
          operation.setContext(({ headers = {} }) => ({
            headers: { ...headers, 'Devo-auth-token': authToken }
          }));
        }
      })
      .then(() => {
        Log.info(`Auth refresh success, retrying ${operation.operationName}...`, 'session', null);
        const subscriber = {
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer)
        };

        // Retry last failed request
        forward(operation).subscribe(subscriber);
      })
      .catch(error => {
        // No refresh or client token available, we force user to login
        observer.error(error);
        Log.warning(`Failed to refresh token and save ${operation.operationName} ${statusCode}`, 'session', error);
        redirectToLogin();
        logAnalytics('logout', { action: 'unauthorized_action' });
        logout();
      });
    })
  }
});

const redirectToLogin = () => {
  if (typeof window === "undefined") {
    Log.error("`window` did not exist when trying to redirect to login!")
    return;
  }
  
  const path = window?.location?.pathname;

  const redirection = { 
    path: path || 'null',
    exceptions: {
      home: path === '/' || false,
      login: path?.includes('/login') || false,
      join: path?.includes('/join') || false,
      referral: path?.includes('/referral') || false,
      integrations: path?.includes('/integrations') || false,
      checkout: path?.includes('/checkout') || false,
      forgotpassword: path?.includes('/auth/change-password') || false,
    }
  };

  const ineligiblePath = path && Object.values(redirection.exceptions).reduce((nxt, curr) => curr || nxt);
  Log.debug('LOGOUT REDIRECT AFTER FAILED REFRESH', 'session', { ...redirection, ineligiblePath });
  
  if (!path || !ineligiblePath) {
    if (path) {
      Router.push(`${LOGIN_ROUTE}?redirect=${window.location.pathname}`);
    } else {
      Router.push(LOGIN_ROUTE);
    }
  }
};

// Early refresh if refresh token expires in 1 month on live, or 30 mins on stable.
export const prefetchAuthToken = () => {
  const refreshToken = getRefreshToken();
  if (!refreshToken) return;

  const refreshInterval = process.env.DEVO_ENV === 'live' ? 2678400 : 1800;
  const expiry = getRefreshExpiryTime();
  if (expiry && expiry < refreshInterval && expiry > 0) {
    Log.info('Prefetching new token...', 'session', { expiry: toHHMMSS(expiry) });
    fetch(`${process.env.API_ACCOUNT_SERVICE}/auth/refresh`, {
      method: 'POST',
      body: JSON.stringify({ refreshToken }),
      headers: {
        'Content-Type': 'application/json',
        'Devo-Api-Key': process.env.DEVO_API_KEY,
      }
    })
    .then(response => {
      if (response.status >= 200 && response.status < 300) {
        return response.json();
      } else {
        if (response.status >= 400 && response.status < 500) {
          Log.debug('Refreshing auth token unauthorized', 'session', null);
        } else {
          Log.error(`Refreshing auth token returned ${response.status}`, 'auth', { status: response.status, response, json: response.json() });
        }
        throw { name : "UnauthorizedRefreshToken", message : "The request to refresh the auth token was unauthorized." }; 
      }
    })
    .then(response => {
      Log.debug('Prefetch success, updating token', 'session', null);
      const authToken = response?.authToken;
      if (authToken) setAuthToken(authToken);
    })
    .catch(error => {
      Log.warning('Failed to prefetch', 'session', error);
    });
  }
};
